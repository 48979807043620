<script setup>
import { computed } from "vue";

const emit = defineEmits(["update:checked"]);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    value: {
        default: null,
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit("update:checked", val);
    },
});
</script>

<template>
    <input v-bind="$attrs" checked="" type="checkbox" :value="value" v-model="proxyChecked" />
    <span class="checkbox"></span>
</template>

<style scoped>
label {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

input[type="checkbox"] {
    position: absolute;
    transform: scale(0);
}

input[type="checkbox"]:checked~.checkbox {
    transform: rotate(325deg);
    width: 30px;
    margin-left: 5px;
    border-color: #30e117;
    border-width: 4px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-radius: 0;
}

.checkbox {
    display: block;
    width: inherit;
    height: inherit;
    border: solid 3px #2a2a2ab7;
    border-radius: 5px;
    transition: all 0.375s;
    padding: 6px;
}
</style>
