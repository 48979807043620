<script setup>
import Checkbox from "@/Components/Checkbox.vue";
import InputError from "@/Components/InputError.vue";
import LoginBtn from "@/Components/LoginBtn.vue";
import TextInput from "@/Components/TextInput.vue";
import { Head, Link, useForm } from "@inertiajs/vue3";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";

defineProps({
  canResetPassword: {
    type: Boolean,
  },
  status: {
    type: String,
  },
});

const form = useForm({
  email: "",
  password: "",
  remember: false,
});

const submit = () => {
  form.post(route("login"), {
    onFinish: () => form.reset("password"),
  });
};
</script>

<template>
  <Head title="Login" />

  <div
    v-if="status"
    class="tw-mb-4 tw-font-medium tw-text-sm tw-text-green-600"
  >
    {{ status }}
  </div>
  <nav class="py-3">
    <Link class="navbar-brand m-0 p-0" :href="route('home.index')">
      <ApplicationLogo width="100" class="mx-auto" />
    </Link>
  </nav>
  <section
    class="loginForm row justify-content-evenly gap-3 align-items-cenetr"
  >
    <form @submit.prevent="submit" class="col-9 col-md-6 col-lg-4 my-auto">
      <div>
        <TextInput
          :label="$t('loginAndRegister.email')"
          lableFor="email"
          id="email"
          type="email"
          class="tw-mt-1 tw-block tw-w-full"
          v-model="form.email"
          required
          autofocus
          autocomplete="username"
        />
        <InputError class="tw-mt-2" :message="form.errors.email" />
      </div>

      <div class="tw-mt-4">
        <TextInput
          :label="$t('loginAndRegister.password')"
          lableFor="password"
          id="password"
          type="password"
          class="tw-mt-1 tw-block tw-w-full"
          v-model="form.password"
          required
          autocomplete="current-password"
        />

        <InputError class="tw-mt-2" :message="form.errors.password" />
      </div>

      <div class="d-flex justify-content-between py-4">
        <label class="tw-flex tw-items-center">
          <Checkbox name="remember" v-model:checked="form.remember" />
          <span class="tw-ml-2 tw-text-sm tw-text-gray-600 pe-1">{{
            $t("loginAndRegister.remember")
          }}</span>
        </label>
      </div>

      <div class="row justify-content-center">
        <LoginBtn
          class="col-12"
          :class="{ 'tw-opacity-25': form.processing }"
          :disabled="form.processing"
        >
          {{ $t("loginAndRegister.login") }}
        </LoginBtn>
        <PrimaryButton class="registre col-12 mt-3">
          <Link :href="route('register')" class="w-100 d-block">
            {{ $t("loginAndRegister.register") }}
          </Link>
        </PrimaryButton>
      </div>
    </form>
    <div
      class="photo col-5 col-md-5 col-lg-5 d-none d-md-flex align-items-center"
    >
      <v-img
        src="/images/Pages/Login/login.jpg"
        lazy-src="/images/Pages/Login/login.jpg"
        aspect-ratio="1"
        width="550"
      >
        <template v-slot:placeholder>
          <div class="d-flex align-items-center justify-content-center h-100">
            <v-progress-circular
              color="grey-lighten-5"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
      </v-img>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.loginForm {
  height: calc(100vh - 132px) !important;
}

.photo {
  position: relative;

  img {
    position: absolute;
    animation: MoveUpDown ease-in-out 2s infinite alternate;
  }
}

.registre {
  border-radius: 25px !important;
}

@keyframes MoveUpDown {
  from {
    transform: translateY(-20px);
  }

  to {
    transform: translateY(20px);
  }
}
</style>
